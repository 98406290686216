import { EntryListAPIResponse } from 'types/ApiResponse';

export interface EntryListQuery {
  order?: EntryListOrder;
  limit?: number;
  categoryId?: number;
}

export type EntryListOrder = 'katsotuimmat' | 'paattyvat' | 'uusimmat';

export async function getEntries(categoryId?: number, order?: EntryListOrder, limit?: number) {
  try {
    const url = new URL(`${process.env.BASE_URL}/api/net-auctions/list`);
    url.search = new URLSearchParams({
      category: categoryId?.toString() ?? '',
      jarjestys: order ?? '',
      limit: limit?.toString() ?? '',
    }).toString();

    const response = await fetch(url, {
      next: { revalidate: 5 * 60 },
    });

    return response.json() as Promise<EntryListAPIResponse>;
  } catch (e) {
    console.error('Failed to fetch entries', e);
    return undefined;
  }
}
